@import "color";

.custom-alert {
    position: fixed;
    top: 120px; /* Giữ nguyên hoặc điều chỉnh top tùy thích */
    right: 20px; /* Thay đổi từ left sang right */
    transform: none; /* Loại bỏ transform */
    padding: 15px;
    border-radius: 5px;
    z-index: 199;
    display: block;
    background-color: $light-color;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-alert-overlay {
    z-index: 198; /* Set a lower z-index for the overlay */
}

.checkmark::before {
    content: "\2714"; /* Unicode character for a checkmark */
    font-size: 20px;
    margin-right: 10px;
    color: $success-alert;
}

.crossmark::before {
    content: "\2716"; /* Unicode character for a cross mark (X) */
    font-size: 20px;
    margin-right: 10px;
    color: $danger-color;
}

button {
    margin-left: auto;
    cursor: pointer;
}
