@import 'color';

#login-content {
    height: auto;
    position: relative;
    display: block;
}

.box {
    width: 600px;
    padding: 50px;
    margin: 10vh auto;
    position: relative;
    /* background-color: rgb(84, 62, 62); */
    text-align: center;
    box-shadow: 0 0 10px rgb(0, 0, 0/0.3);
    border-radius: 2px;
}

.box h1 {
    color: $primary-color;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 40px;
}

.box #email,
.box #ps {
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #939393;
    padding: 14px 10px;
    width: 200px;
    outline: none;
    color: rgb(0, 0, 0);
    border-radius: 2px;
    transition: 0.25s;
    background-color: #ffffff;
}
.box #email:focus,
.box #ps:focus {
    width: 280px;
    border-color: $primary-color;
}
.box input[type="submit"] {
    border: 0;
    background: none;
    display: block;
    margin: 20px auto;
    text-align: center;
    border: 2px solid #939393;
    padding: 14px 40px;
    outline: none;
    color: #939393;
    border-radius: 2px;
    transition: 0.25s;
    cursor: pointer;
}
.box input[type="submit"]:hover {
    background-color: $primary-color;
    color: #ffffff;
}
