@import "color";
* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: "Lexend", sans-serif;
    text-transform: none;
    text-overflow: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
#app {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
}

ol,
ul {
    list-style: none;
}
