@import "color";

.dropdown-contentSearch {
    position: absolute;
    width: 700px;
    left: 0;
    top: 45px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 10;
    max-height: 100%;

    #searchBtn {
        width: 40px;
        height: 42px;
        margin-left: -40px;
        background-color: transparent;
        border: none;
        overflow-y: hidden;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .searchresultcontent {
        background-color: #fff;
        padding: 20px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        height: auto;
        overflow: hidden;
        .searchResults {
            align-items: center;
            list-style: none;
            padding: 0;
            width: 100%;
            height: 100%;
            .inforsearch {
                display: flex;
                flex-direction: row;
                align-items: center;
                max-height: 70px;
                padding: 10px 0;
                border-bottom: 1px solid #e7e7e7;
                cursor: pointer;
                &:last-child {
                    border: none; // Loại bỏ đường viền cho khung sản phẩm cuối cùng
                }
                &:hover a {
                    transition: 0.3s;
                    color: $primary-color !important;
                }
                .productInfo-name {
                    height: 50px;
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap; /* Ngăn tên sản phẩm xuống hàng */
                    width: calc(100% - 80px);
                    overflow: hidden; /* Ẩn đi phần nội dung vượt quá độ rộng */
                    text-overflow: ellipsis;
                    a {
                        text-decoration: none;
                        color: #333;
                        font-weight: 500;
                    }
                    .price-search {
                        margin-top: 25px;
                        font-size: 14px;
                        line-height: 14px;
                        line-height: 0;
                        font-weight: bold;
                    }
                }
                .thumbnail {
                    margin-left: auto;

                    img {
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                    }
                }
            }
            .more {
                width: 100%;
                text-align: center;
                font-size: 14px;
                a {
                    text-decoration: none;
                    color: #333;
                }
            }
        }
    }
}
#content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px; // Khoảng cách giữa các sản phẩm
}

.post-items {
    border: 1px solid #ccc; // Viền sản phẩm
    height: fit-content;
}

.post-content {
    text-align: center;
    a {
        font-size: 16px !important;
    }
}

.allproductLayout {
    margin-bottom: 50px;
    .title {
        text-transform: uppercase;
        border-bottom: 1px solid #e7e7e7;
        margin: 0 150px;
    }

    .post-grids-formain {
        display: grid;
        width: auto;
        justify-content: center;
        grid-template-columns: 260px 260px 260px 260px;
        grid-template-rows: 1fr auto;
        grid-gap: 20px;
        margin: 50px;
        margin-top: 30px;
        background-color: #fff;
        .post-items {
            height: fit-content;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
                rgba(0, 0, 0, 0.24) 0px 1px 2px;
            &:hover {
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                    rgba(0, 0, 0, 0.23) 0px 3px 6px;
            }
            .post-content2 {
                background-color: #fff;
                border-top: 1px solid #e7e7e7;
                font-weight: normal;
                font-size: 14px;
                color: #000;
                text-align: center;
                padding: 0 10px;
                a {
                    text-decoration: none;
                    color: #000;
                    font-weight: 600;
                    font-size: 16px;
                    z-index: 10;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .price {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: red !important;
                    font-weight: bold;
                    font-size: 16px;
                    padding-left: 20px;
                    margin: 0;
                    #old-price {
                        color: #333333;
                        font-weight: 400;
                        font-size: 15px;
                        padding-left: 10px;
                        margin-bottom: 0;
                        text-decoration: line-through #333333;
                    }
                }
                .underframe {
                    display: flex;
                    justify-content: space-between;
                    .star-rating {
                        color: #dc2f2f;
                    }
                }
            }
        }
    }
    .btn-collection {
        max-width: 100%;
        text-align: center;
        a {
            max-width: 990px;
            padding: 10px 40px;
            border: 1px solid #606060;
            border-radius: 5px;
            color: #dc2f2f;
            font-size: 14px;
            text-decoration: none;
        }
    }
}

.post-grids {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, minmax(200px, 250px));
    grid-gap: 20px;
    margin: 30px;
}

.overlayout-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    &:hover > img {
        transform: scale(1.1); // Hiệu ứng phóng to khi di chuột qua sản phẩm
    }
    img {
        width: 200px;
        max-height: 200px;
        transition: all 0.5s ease-in-out;
    }
}

#discount-percent--contents {
    position: absolute;
    background-color: #ff0000; // Màu đỏ cho phần giảm giá
    z-index: 3;
    color: #fff;
    padding: 5px;
    width: fit-content;
}

.price {
    font-weight: bold;

    #old-price {
        text-decoration: line-through; // Gạch ngang giá cũ
    }
}
