:root {
    --main-bg-color: #ec7263;
    --main-text-color: #ec7263;
    --second-text-color:#555;
    --second-bg-color: #fff;
}

.primary-text {
    color: var(--main-text-color);
}

.second-text {
    color: var(--second-text-color);
}

.primary-bg {
    background-color: var(--main-bg-color);
}

.secondary-bg {
    background-color: var(--second-bg-color);
}

.rounded-full {
    border-radius: 100%;
}

#wrapper {
    overflow-x: hidden;
    background-image: linear-gradient(
        to right,
        #cbb581,
        #f5e7c2,
        #f7e8cb,
        #f8f2d4,
        #faebdd
    );
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

#menu-toggle {
    cursor: pointer;
}

.list-group-item {
    border: none;
    padding: 20px 30px;
}

.list-group-item.active {
    background-color: transparent;
    color: var(--main-text-color);
    font-weight: bold;
    border: none;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}
