$primary: #153448;
$secondary: #3c5b6f;
$white: #000;
$gray: #9b9b9b;
.checkout-container {
    .logoandtitle {
        border-bottom: 1px solid #e7e7e7;
        padding-left: 150px;
        padding-top: 20px;
        width: 100%;
    }
    #loader {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.85);
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .overlayout--checkout {
        display: flex;
        justify-content: space-between;
        position: relative;

        .user-details {
            margin: 20px 0;
            margin-left: 150px;
            width: 50%;
            .userInfo {
                display: flex;
                flex-direction: column;
                display: flex;
                .userTag {
                }
                .formOrder {
                    width: 90%;
                    .location--picker {
                        padding-top: 20px;
                        display: flex;
                        justify-content: space-around;
                        #city {
                            width: 170px;
                        }
                    }
                    .form__group {
                        position: relative;
                        padding: 15px 0 0;
                        margin-top: 10px;
                        width: 100%;
                    }
                    input {
                        height: 40px;
                        margin-left: 5px;
                    }
                    .checkbox--list {
                        width: 100%;
                        margin-top: 20px;
                        h4 {
                            font-size: 22px;
                        }
                        label {
                            width: 100%;
                            height: 50px;
                            font-size: 14px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border: 1px solid $primary;

                            div {
                                width: 70%;
                                height: 100%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                input {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
                #submitOrder {
                    border: 1px solid $primary;
                    outline: none;
                    margin-top: 20px;
                    font-weight: bold;
                    color: $primary;
                    height: 50px;
                    background-color: transparent;
                    max-width: 90%;
                    transition: 0.3s;
                    &:hover {
                        color: #fff;
                        background-color: #3c5b6f;
                    }
                }
            }
        }
        .order-details {
            width: 50%;
            border-left: 1px solid #e7e7e7;
            .product--infomation {
                margin: 20px 20px;
                margin-right: 150px;
                .productList {
                    display: flex;
                    li {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $secondary;
    outline: 0;
    font-size: 16px;
    color: $white;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
        color: transparent;
    }

    &:placeholder-shown ~ .form__label {
        font-size: 20px;
        cursor: text;
        top: 20px;
    }
}

.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $secondary;
}

.form__field:focus {
    ~ .form__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 1rem;
        color: $primary;
        font-weight: 700;
    }
    padding-bottom: 6px;
    font-size: 18px;
    border-width: 3px;
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;
}
.form__field {
    &:required,
    &:invalid {
        box-shadow: none;
    }
}

.custom-select {
    position: relative;
    font-family: Arial;
}

select {
    /* styling */
    background-color: white;
    border: thin solid $primary;
    border-radius: 4px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
    /* reset */

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}
select.minimal {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
}

select.minimal:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, green 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
