@import "color";

.catalog {
    width: 20%;
    height: 100%;
    border: solid 1px #333333;
    background-color: #fff;
    text-transform: uppercase;

    .catalog_title {
        width: 100%;
        height: 30px;
        text-align: center;
        border-bottom: solid 1px #333333;
        background-color: $primary-color;
        padding-top: 2px;

        span {
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
        }
    }

    .catalog_item {
        display: flex;
        text-transform: uppercase;
        flex-direction: column;
        justify-content: space-around;
        list-style: none;
        height: 90%;
        font-weight: 600;
        .mobile-menu__help {
            padding: 20px 0;
            .help-title {
                font-size: 13px;
                margin: 20px 0;
                color: #333333;
                line-height: 1.5;
                text-transform: uppercase;
                font-weight: 500;
                font-style: normal;
            }
            .help-item--link {
                svg {
                    margin-right: 14px;
                    width: 16px;
                    height: 24px;
                }
                color: #333333;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0;
                font-size: 14px;
                border: 0;
                padding: 7px 0;
            }
        }
        a {
            text-transform: uppercase;
            height: 30px;
            font-size: 15px;
            margin: 4px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #000;
            text-decoration: none;
        }
        .active {
            text-transform: uppercase;
            height: 30px;
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.catalogfixed {
    width: 250px;
    height: 400px;
    top: 12vh;
    border: solid 1px #333333;
    background-color: #fff;
    text-transform: uppercase;
    position: fixed;
    .catalog_title {
        width: 100%;
        height: 30px;
        text-align: center;
        border-bottom: solid 1px #333333;
        background-color: $primary-color;
        padding-top: 2px;

        span {
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
        }
    }

    .catalog_item {
        display: flex;
        text-transform: uppercase;
        flex-direction: column;
        justify-content: space-around;
        list-style: none;
        height: 90%;
        font-weight: 600;
        margin: 10px 20px;

        .mobile-menu__help {
            padding: 20px 0;
            .help-title {
                font-size: 13px;
                color: #333333;
                line-height: 1.5;
                text-transform: uppercase;
                font-weight: 500;
                font-style: normal;
            }
            .help-item--link {
                svg {
                    margin-right: 14px;
                    width: 16px;
                    height: 24px;
                }
                color: #333333;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin: 0;
                font-size: 14px;
                border: 0;
                padding: 7px 0;
            }
        }
        a {
            text-transform: uppercase;
            height: 30px;
            font-size: 15px;
            margin: 4px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #000;
            text-decoration: none;
            &:hover {
                color: $primary-color;
            }
        }
        .active {
            text-transform: uppercase;
            height: 30px;
            font-size: 15px;
            color: #000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.overlayouthome {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    height: 420px;
    max-width: 1100px;
    justify-content: space-between;
    .catalog {
        width: 20%;
        height: 100%;
        border: solid 1px #333333;
        .catalog_title {
            width: 100%;
            height: 30px;
            text-transform: uppercase;
            text-align: center;
            border-bottom: solid 1px #333333;
            background-color: $primary-color;
            padding-top: 2px;
            span {
                color: #fff;
                font-weight: bold;
                font-size: 14px;
                line-height: 15px;
            }
        }
        .catalog_item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            list-style: none;
            margin: 5px 15px;
            .active {
                height: 40px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .banner-section {
        width: 80%;
        overflow: hidden;

        .slider-section {
            position: relative;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            height: 100%;
            .home-img {
                width: 75%;
                height: 100%;
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15),
                    0 2px 2px rgba(0, 0, 0, 0.05), 0 4px 4px rgba(0, 0, 0, 0.05),
                    0 8px 8px rgba(0, 0, 0, 0.05);
                overflow: hidden;
                .slider-img {
                    width: 100%;
                    height: 420px;
                    display: block;
                }
            }
            .sub-img {
                width: 25%;
                height: 100%;
                background-color: #333333;
                .subslider-img {
                    width: 100%;
                    height: 50%;
                }
            }
        }
    }
}



#content {
    max-width: 100%;
    min-height: 100%;
    .section-index-promotion {
        margin-top: 1px;
        margin-bottom: 30px;
        .container {
            max-width: 1120px;
            .promotion-bgwhite {
                padding: 10px 12px;
                // overflow: hidden;
                .list-promotion {
                    margin: 0 -8px;
                    display: -ms-flexbox;
                    display: flex;
                    .promotion-item {
                        flex: 0 0 25%;
                        -ms-flex: 0 0 25%;
                        max-width: 25%;
                        padding: 0 8px;
                        .promotion-item__inner {
                            height: 100%;
                            border-radius: 4px;
                            border: 1px solid $primary-color;
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .title {
                                font-weight: 600;
                                font-size: 17px;
                                margin: 0;
                                text-align: left;
                                border: none;
                            }
                            .content {
                                font-size: 13px;
                            }
                            span {
                                display: block;
                                padding: 0;
                            }
                            a {
                                color: var(--shop-color-main);
                                text-decoration: none;
                                outline: none;
                                -moz-transition: all 0.3s ease-in-out;
                                -o-transition: all 0.3s ease-in-out;
                                -webkit-transition: all 0.3s ease-in-out;
                                transition: all 0.3s ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}

.post-item {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #000;
    transition: 0.5s;
    border: solid 10px #fff;
    .post-content,
    .overlayout {
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
    }
    .over-btn {
        width: 100%;
        height: 28px;
    }
    .indetail {
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
    }
}

.post-item:hover {
    .detail-btn {
        opacity: 1;
        bottom: 10px;
    }
}

.overlayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .overlayout-img {
        width: 100%;
        max-height: 100%;
        background-position: center;
        object-fit: cover;
        overflow: hidden;
        background-size: cover;
        a {
            display: flex;
            justify-content: center;
            img {
                width: 100%;
                height: 200px;
                transition: 0.5s;
                transform: scale(1);
            }
        }
        :hover {
            transform: scale(1.1);
        }
    }
}

#largest-img,
#largest-img--sp {
    height: 100%;
    width: 100%;
}

#largest-img--bg {
    height: 325px;
    width: 100%;
}

.content_img {
    display: flex;
    flex-direction: column;
    border-radius: 0px;
    width: 100%;
    height: 232px;
    margin: auto;
    object-fit: cover;
    height: 25 0px;
    flex-shrink: 0;
}
.post-content {
    background-color: #fff;
    border-top: 1px solid #e7e7e7;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    text-align: center;
    padding: 0 10px;

    a {
        text-decoration: none;
        color: #000;
        font-weight: 600;
        font-size: 12px;
        z-index: 10;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    a:hover {
        font-weight: 600;
        text-decoration: none;
    }
    .price {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: red !important;
        font-weight: bold;
        font-size: 16px;
        margin: 0;

        #old-price {
            color: #333333;
            font-weight: 400;
            font-size: 15px;
            padding-left: 10px;
            margin-bottom: 0;
            text-decoration: line-through #333333;
        }
    }
    .underframe {
        display: flex;
        justify-content: space-between;
        .star-rating {
            color: #dc2f2f;
        }
    }
}

.title {
    text-align: center;
    padding-top: 20px;
    /* margin-left: 50px; */
    color: $primary-color !important;
    font-size: 10px;
    text-transform: uppercase;
    border-bottom: 1px solid #e7e7e7;
    margin: 0 150px;
}
.title h2 {
    padding-bottom: 8px;
}

.post-grid {
    width: 100%;
    height: auto;
    display: grid;
    justify-content: center;
    top: 0;
    grid-template-columns: 225px 225px 225px 225px;
    grid-template-rows: 1fr auto;
    grid-gap: 25px;
    grid-template-areas:
        "h1 h2 h3 h4"
        "h5 h6 h7 h8";
    padding: 35px;
}

.post-grid--sc {
    width: 100%;
    height: auto;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1120px);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    padding: 25px;
}

.imagebackground {
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: auto;
    width: 100%;
    img {
        max-width: 1100px;
        height: auto;
        transition: 0.7s;

        &:hover {
            transform: scale(1.1);
            transition: 0.7s;
            cursor: pointer;
        }
    }
}

/* Các quy tắc CSS khác tùy thuộc vào nhu cầu thiết kế của bạn */

#discount-percent {
    display: flex;
    justify-content: flex-start;
    z-index: 2;
    max-height: 25px;
    position: absolute;
    width: 100%;
    // max-width: 50px;
    margin: 0;
    #discount-percent--content {
        background-color: #dc2f2f;
        height: 100%;
        width: auto;
        margin-right: 30px;
        padding: 3px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;
        border-radius: 5px;
        color: #fff;
    }
}

.over-btn {
    position: absolute;
    max-width: 200px;
    max-height: 28px;
}

.detail-btn {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: auto;
    background-color: #ff894c;
    border-radius: 5px;
    height: 100%;
    position: relative;
    bottom: -55px;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s;
}

.detail-btn button {
    border: none;
    background-color: transparent;
    margin: 0px 3px;
    color: #fff;
    margin-top: 1px;
    text-transform: uppercase;
    font-weight: 500;
    // font-size: 8px;
}

#cart {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
    cursor: pointer;
    // background-color: #FFF;
    i {
        color: #ffffff;
        // color: #ff894c;
        font-size: 14px;
    }
}

.indetail {
    height: 80%;
    width: 90%;
    background-color: #ffffff !important;
    border-radius: 5px;
    color: #ff894c;
    cursor: pointer;
    margin: 3px 3px !important;
    font-size: 14px;
    transition: 0.5s;
}

.indetail:hover {
    background-color: #ff894c !important;
    transition: 0.5s;
    color: #ffffff;
}
