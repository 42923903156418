.user-information {
    font-size: 1.2rem;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    height: 100%;
    .info-container {
        height: 100%;
    }

    .dropdown--detail {
        padding: 0 !important;
        padding-left: 25px !important;
        width: 100%;
    }
    .container {
        width: 100%;
        display: block;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }

    .AccountDropdown {
        position: absolute;
        width: 150px;
        height: 180px;
        margin-top: 5px;
        font-size: 14px;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        .dropdown-line {
            width: 100%;
            height: 42px;
            text-align: left;
            .dropdown--detail {
                height: 100%;
                width: 100%;
                display: block;
                color: #233142;
                padding-left: 30px;
                padding-top: 15px;
                text-decoration: none;
            }
        }
    }
    .info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .name {
            padding: 0;
            font-size: 16px;
            margin: 0;
            color: #7b7e7e;
        }
    }
}
