.overlayout--productdetail {
    display: block;
    width: 1200px;
    margin: 0 auto;
    .product-detail {
        display: flex;
        justify-content: space-around;
        position: relative;
        margin-top: 15px !important;
        margin: 50px 0;
        .product-infoImage {
            display: flex;
            justify-content: space-around;
            width: 50%;
            height: 100%;
            .product-image {
                border: 1px solid #e3e3e3;
                width: 85%;
                img {
                    display: block;
                    width: 470px;
                    height: 470px;
                    object-fit: contain;
                    transition: all 0.5s ease-in-out;
                }
            }
            .slider {
                width: 15%;
                height: 470px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                &-main-image {
                    width: 600px;
                    height: 600px;
                    overflow: hidden;
                    border-radius: 10px;
                    margin-right: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &-images {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    .slider-image {
                        display: flex;
                        justify-content: space-around;
                        width: 70px;
                        border: 1px solid #e3e3e3;
                        height: 70px;
                        cursor: pointer;
                        overflow: hidden;
                        margin-bottom: 5px;
                        &:hover {
                            border: 2px solid #fca311;
                        }
                        img {
                            display: flex-start;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .product-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 50%;
            margin-left: 50px;

            .product-name {
                font-size: 25px;
                padding-bottom: 20px;
                border-bottom: 1px solid #e3e3e3;
            }

            .product-description {
                padding-top: 15px;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
            }
            .product-price {
                font-size: 1.5em;
                margin-bottom: 20px;
                color: red;
                font-weight: 500;
            }
            .productOrder {
                width: 100%;
                height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin-bottom: 5px;
                border-bottom: 1px solid #e3e3e3;
                .nameOrder {
                    width: 100%;
                    height: 50px;
                    margin-left: 20px;
                    font-size: 20px;
                    font-weight: bold;
                }
                .quantityOrder {
                    display: flex;
                    align-items: center;
                    margin-left: 20px;
                    font-weight: 500;
                    font-size: 14px;
                    div {
                        color: #f95959;
                        font-size: 20px;
                        padding-right: 5px;
                    }
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        margin: 0;
                        text-transform: uppercase;
                        span {
                            font-weight: bold;
                        }
                    }
                }
                .indetailOrder {
                    display: flex;
                    width: 90%;
                    justify-content: flex-start;
                    margin-left: 20px;

                    .discountOrder {
                        width: 60px;
                        height: 35px;
                        background-color: #e3e3e3;
                        text-align: center;
                        line-height: 35px;
                        font-size: 14px;
                        color: #f95959;
                        font-weight: 600;
                    }
                    .priceOrder {
                        display: flex;
                        margin-left: 10px;
                        div {
                            margin: 0 5px;
                            height: 35px;
                            line-height: 35px;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
                .Order {
                    display: flex;
                    margin-left: 20px;
                    width: 100%;
                    .amountOrder {
                        width: 45px;
                        height: 45px;
                        background-color: #f3f4f4;
                        border: none;
                        font-weight: bold;
                    }
                    .amountInput {
                        width: 80px;
                        height: 45px;
                        text-align: center;
                    }
                    .OrderSubmit {
                        background-color: #ffc470;
                        border: none;
                        width: 100px;
                        margin-left: 20px;
                        font-weight: 500;
                        color: #fff;
                        transition: 0.3s;
                    }

                    .OrderSubmit:hover {
                        background-color: #fbf8dd;
                        color: #1e1d1d;
                    }

                    .cart {
                        width: 235px;
                        height: 45px;
                        line-height: 40px;
                        margin-left: 10px;
                        background-color: #fff;
                        button {
                            display: block;
                            width: 100%;
                            height: 100%;
                            color: #fff;
                            text-decoration: none;
                            border: none;
                            text-transform: uppercase;
                            outline: none;
                            font-weight: 500;
                            transition: all 0.5s;
                            position: relative;
                            background-color: #fff;
                            font-size: 1rem;
                            letter-spacing: 0.15rem;
                            overflow: hidden;
                            z-index: 1;
                            span {
                                text-transform: uppercase;
                                font-size: 0.8rem;
                                margin-bottom: 2rem;
                                height: 100%;
                                width: 100%;
                            }
                            &:after {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: red;
                                z-index: -2;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 0%;
                                height: 100%;
                                background-color: #fff;
                                transition: all 0.5s;
                                z-index: -1;
                            }
                            &:hover {
                                color: red;
                                outline: 1px solid red;
                                &:before {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                .info {
                    display: flex;
                    justify-content: space-around;
                    width: 80%;
                    height: 40%;
                    padding: 0;
                }
                .photoOrder {
                    width: 80px;
                    height: 80px;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .moredetail {
                display: flex;
                justify-content: space-around;
                height: 50%;
                width: 100%;
                margin-left: 20px;

                .moredetail--title {
                    font-size: 20px;
                    padding: 10px 0;
                    font-weight: 500;
                }
                .first,
                .second,
                .third {
                    margin-left: 10px;
                    padding-bottom: 10px;
                    svg {
                        color: #fca311;
                        font-size: 25px;
                    }
                    span {
                        font-size: 16px;
                        padding-left: 15px;
                    }
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .recommendSection {
        margin: 0 auto;
        padding: 40px 0;
        max-width: 1200px;
        #span {
            border-bottom: 1px solid #e3e3e3;
            margin-bottom: 15px;
            width: 100%;
            h2 {
                padding-left: 20px;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .product-overview {
        max-width: 1200px;
        padding: 20px 0;
        margin: 0 auto;
        .explain {
            border-bottom: 1px solid #e3e3e3;
            div {
                display: flex;
                justify-content: space-around;
                width: 400px;
                height: 100%;
                cursor: pointer;
                span {
                    text-align: center;
                    width: 50%;
                    border-bottom: 2px solid #f95959;
                    h2 {
                        text-transform: uppercase;
                        // background-color: #ffc470;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .product-sub {
            width: 100%;
            // box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.1),
            //     0 2px 6px 2px rgba(60, 64, 67, 0.15);
            .infomation {
                position: relative;
                padding-top: 20px;
                #opacitydown {
                    position: absolute;
                    width: 100%;
                    height: 100px;
                    background: linear-gradient(
                        180deg,
                        rgba(255, 255, 255, 0) 17%,
                        #fff 93.12%
                    );
                    bottom: 40px;
                }
                .fade-out {
                    opacity: 0; /* Set opacity to 0 when the class is applied */
                }
                .morebutton-section {
                    width: 100%;
                    text-align: center;

                    .morebutton,
                    .lessbutton {
                        border: 1px solid #fca311;
                        outline: none;
                        color: #fca311;
                        width: 100px;
                        height: 40px;
                        background-color: transparent;
                        font-weight: 500;
                        transition: 0.3s;
                        &:hover {
                            color: #fff;
                            background-color: #fca311;
                        }
                    }
                }
            }
        }

        .user-review {
            width: 100%;
            .product-review {
                padding: 30px;

                .explain2 {
                    padding-bottom: 15px;
                    border-bottom: 1px solid #e3e3e3;
                    h2 {
                        text-transform: uppercase;
                        width: 100%;
                        // background-color: #ffc470;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                }
                .review-detail {
                    width: 100%;

                    .over--review {
                        width: 100%;
                        .rating-score {
                            display: flex;
                            justify-content: space-around;
                            border-bottom: 1px solid #e7e7e7;
                            padding: 15px 0;
                            #border {
                                border-right: 1px solid #e3e3e3;
                            }
                            .your-review {
                                .review--action {
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    padding: 5px;
                                    border-radius: 5px;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                }
                            }
                            p {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                margin: 0;
                                padding: 5px;
                                border-radius: 5px;
                                font-weight: bold;
                                width: 180px;
                                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                                    rgba(0, 0, 0, 0.23) 0px 3px 6px;

                                div {
                                    font-size: 25px;
                                    span {
                                        padding-right: 4px;
                                        color: #fca311;
                                    }
                                }
                            }
                        }
                        .rating-quantity {
                            width: 100%;
                            margin-top: 15px;
                            .comment {
                                span {
                                    margin: 0;
                                    padding: 0;
                                    display: flex;
                                    justify-content: space-between;
                                    margin-bottom: 5px;
                                    button {
                                        border: none;
                                        outline: none;
                                        width: 40px;
                                        color: #fff;
                                        background-color: #f95959;
                                    }
                                }
                            }
                            .user-rating {
                                display: flex;
                                flex-direction: column;
                                margin-bottom: 7px;
                                border-bottom: 1px solid #e7e7e7;
                                &:last-child {
                                    border-bottom: none;
                                }
                                .header-rating {
                                    display: flex;
                                    justify-content: space-between;
                                    margin: 0;
                                    padding: 0;
                                    color: #f95959;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .viewedProducts {
        margin: 0 130px;
        .product-item {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #000;
            transition: 0.5s;
            border: solid 1px #3333;
            .over-btn {
                width: 100%;
                height: 28px;
            }
            .indetail {
                text-decoration: none;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .product-item--banner {
            width: 100%;
            height: 100%;
        }
        .product-item:first-child {
            grid-area: h1;
            overflow: hidden;
        }

        .product-item:hover {
            .detail-btn {
                opacity: 1;
                bottom: 10px;
            }
        }
    }
}
.categoryBar {
    max-width: 1170px;
    background-color: #fff;
    display: flex;
    margin: 15px 120px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #e7e7e7;
    p,
    a {
        margin: 0 15px;
        font-weight: 500;
        font-size: 14px;
        font-family: 14px;
        color: #333;
        text-decoration: none;
    }
}
