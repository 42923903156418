@import 'color';

.dropdown {
    position: absolute !important;
    overflow: hidden;
    left: 70%;
    top: 65px;
    max-width: 400px;
    height: auto;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 3;
    background-color: #fff;
    .dropdown-contentLogin {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .dropdown-title {
            width: 80%;
            height: 23%;
            text-align: center;
            border-bottom: 1px solid #999;
            .Login--title {
                width: 100%;
                height: 25px;
                font-size: 20px;
                font-weight: 500;
                color: $primary-color;
            }
            .LoginDetail--title {
                width: 100%;
                height: 25px;
                font-size: 14px;
                padding-bottom: 10px;
            }
        }
        .formdata {
            margin-top: 5px;
            text-align: center;
            width: 90%;
            height: 52%;
            .login--btn {
                width: 100%;
                height: 40px;
                background-color: $primary-color;
                text-align: center;
                line-height: 40px;
                font-weight: 500;
                border: none;
                border-radius: 4px;
                color: #fff;
            }
            #email,
            #ps {
                width: 100%;
                height: 40px;
                padding-left: 15px;
                border: 1px solid #d4d6d8;
            }
        }
    }
    .Login--opt {
        width: 80%;
        height: 80px;
        padding: 0 35px;
        font-size: 14px;
        a {
            text-decoration: none;
            color: $primary-color;
        }
    }
}

.dropdownSearch {
    position: absolute !important;
    overflow: hidden;
    width: 35%;
    height: 500px;
    right: 441px;
    top: 55px;
    border-radius: 4px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 3;
    background-color: #fff;
    .dropdown-contentSearch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .formdataSearch {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            .searchInput {
                width: 380px;
                height: 42px;
                margin-top: 25px;
                padding-left: 20px;
            }
            #searchBtn {
                position: absolute;
                right: 40px;
                margin-top: 15px;
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
            .dropdown-titleSearch {
                width: 100%;
                text-align: center;
                border-bottom: 1px solid #9999;
                margin-top: 15px;
                .searchTitle {
                    font-size: 18px;
                }
            }
            .login--btn {
                width: 270px;
                height: 42px;
                background-color: $primary-color;
                text-align: center;
                line-height: 42px;
                font-weight: 500;
                border: none;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
}

.dropdownSearchLogged {
    position: absolute !important;
    overflow: hidden;
    width: 400px;
    height: 150px;
    right: 150px;
    top: 65px;
    border-radius: 4px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 3;
    background-color: #fff;
    .dropdown-contentSearch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .formdataSearch {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            .searchInput {
                width: 380px;
                height: 42px;
                margin-top: 25px;
                padding-left: 20px;
            }
            #searchBtn {
                position: absolute;
                right: 40px;
                margin-top: 15px;
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
            .dropdown-titleSearch {
                width: 100%;
                text-align: center;
                border-bottom: 1px solid #9999;
                margin-top: 15px;
                .searchTitle {
                    font-size: 18px;
                }
            }
            .login--btn {
                width: 270px;
                height: 42px;
                background-color: $primary-color;
                text-align: center;
                line-height: 42px;
                font-weight: 500;
                border: none;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
}

.dropdownCart {
    position: absolute !important;
    overflow: hidden;
    width: 410px;
    height: 200px;
    right: 65px;
    top: 65px;
    border-radius: 4px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 3;
    background-color: #fff;
    .dropdown-contentCart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .dropdown-title {
            width: 90%;
            height: 80%;
            text-align: center;
            .Login--title {
                width: 100%;
                font-size: 22px;
                font-weight: 500;
                color: $primary-color;
                border-bottom: 1px solid #999;
            }
            .LoginDetail--title {
                width: 100%;
                font-size: 14px;
                font-weight: 200;
            }
        }
        .Login--opt {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 80%;
            height: 250px;
            margin-top: -15px;
            font-size: 14px;
            a {
                text-decoration: none;
                color: $primary-color;
            }
            #reset-ps {
                margin-top: -50px;
            }
        }
    }
}

.dropdownCartLogged {
    position: absolute !important;
    overflow: hidden;
    width: 450px;
    // height: 450px;
    right: 100px;
    top: 60px;
    border-radius: 4px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    z-index: 3;
    background-color: #fff;
    // scrollbar-width: thin;

    .dropdownCartContent {
        width: 100%;
        height: 100%;

        .titleCart {
            text-align: center;
            border-bottom: 1px solid #e7e7e7;
            font-size: 18px;
            padding: 20px 0;
            margin: 0 20px;
            font-weight: 600;
            color: $primary-color;
        }
        .dropdown-cart {
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #e7e7e7;
            svg {
                height: 50px;
                width: 50px;
                color: $primary-color;
            }
            p {
                margin: 0;
                color: #999;
                text-transform: uppercase;
                font-size: 15px;
            }
        }
        .dropdown-contentCartLogged {
            width: 100%;
            overflow-y: auto;
            max-height: 400px;
            padding: 0 20px;
            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #555;
            }
            #miniCartInfo {
                display: flex;
                position: relative;
                align-items: center;
                width: 100%;
                height: 110px;
                border-bottom: 1px solid #e7e7e7;
                #miniCart {
                    position: relative;
                    width: 100%;
                    #miniCartImg {
                        width: auto;
                    }
                    #miniCartTitle {
                        display: block;
                        height: auto;
                        line-height: normal;
                        text-transform: uppercase;
                        padding-left: 15px;
                        text-overflow: ellipsis;
                        overflow: hidden; /* Ẩn đi phần nội dung vượt quá độ rộng */
                        width: calc(100% - 25px) !important;
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            font-size: 12px;
                            font-weight: 600;
                            color: #1c1c1c;
                            text-decoration: none;
                        }
                        span {
                            font-size: 12px;
                        }
                    }
                    .priceandqt {
                        display: flex;
                        justify-content: space-between;
                        align-items: center; /* Căn giữa theo trục dọc */
                        height: 25px;
                        padding-left: 15px;
                        margin-top: 15px;
                        .qtsection {
                            display: block;
                            white-space: nowrap;
                            height: 25px;
                            line-height: 25px;
                            .qt-btn {
                                // display: inline-block;
                                height: 100%;
                                color: $primary-color;
                                width: 25px;
                                border-radius: 50%;
                                font-size: 18px;
                                line-height: 18px;
                                outline: none;
                                border: 1px solid rgba(107, 68, 51, 0.06);
                            }

                            .item-qt {
                                font-size: 15px;
                                line-height: 15px;
                                height: 100%;
                                width: 30px;
                                border: none;
                                outline: none;
                                text-align: center;
                            }
                        }
                        #priceCart {
                            font-size: 15px;
                            font-weight: bold;
                        }
                    }
                }
                #deletecartinfo {
                    position: absolute;
                    top: 25px;
                    right: 10px;
                    cursor: pointer;
                    img {
                        display: block;
                        width: 14px;
                        height: 14px !important;
                    }
                }
            }
        }
        .miniCartFooter {
            .totalPrice {
                display: flex;
                justify-content: space-between;
                height: 40px;
                padding: 0 30px;
                font-size: 15px;
                font-weight: 500;
                text-transform: uppercase;
                #totalprice {
                    color: red;
                    font-weight: bold;
                }
            }
            .toCart {
                width: 100%;
                max-height: 100%;
                text-align: center;
                margin: 15px 0;
                .cart {
                    width: 80%;
                    height: 40px;
                    line-height: 40px;
                    margin: 0 auto;

                    background-color: #fff;
                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: #fff;
                        text-decoration: none;
                        border: none;
                        text-transform: uppercase;
                        outline: none;
                        font-weight: 500;
                        transition: all 0.5s;
                        position: relative;
                        background-color: #fff;
                        font-size: 1rem;
                        letter-spacing: 0.15rem;
                        overflow: hidden;
                        z-index: 1;
                        span {
                            text-transform: uppercase;
                            font-size: 0.8rem;
                            margin-bottom: 2rem;
                            height: 100%;
                            width: 100%;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: red;
                            z-index: -2;
                        }
                        &:before {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0%;
                            height: 100%;
                            background-color: #fff;
                            transition: all 0.5s;
                            z-index: -1;
                        }
                        &:hover {
                            color: red;
                            outline: 1px solid red;
                            &:before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
.dropdownSub {
    position: absolute;
    background-color: #fff;
    width: 800px;
    height: 388px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 10px;
    top: 0;
    overflow-y: auto;
    display: flex;
    z-index: 10;
    text-transform: uppercase;
    transition: 0.5s;
    .wrapperCategory {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .catalogWrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .catalogItem {
                flex: 1 1 25%;
                max-width: 25%;
                padding: 15px 10px;
                .catalogTitle {
                    font-weight: bold;
                    margin-bottom: 5px;
                    color: #000;
                    font-size: 16px;
                }
                .children {
                    display: flex;

                    flex-wrap: wrap;
                    .child {
                        margin-right: 10px;
                        margin-bottom: 5px;
                        transition: 0.3s;
                        font-weight: 500;
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            font-weight: 500;
                            font-size: 12px;
                            &:hover {
                                color: $primary-color !important;
                                transition: 0.3s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dropdownSub2 {
    position: absolute;
    background-color: #fff;
    width: 800px;
    height: 388px;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 10px;
    top: 0;
    overflow-y: auto;
    display: flex;
    z-index: 10;
    text-transform: uppercase;
    transition: 0.5s;
    .wrapperCategory {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .catalogWrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 100%;
            .catalogItem {
                flex: 1 1 25%;
                max-width: 25%;
                .catalogTitle {
                    font-weight: bold;
                    color: #000;
                    font-size: 16px;
                }
                .children {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 25px;

                    .child {
                        margin-right: 10px;
                        margin-bottom: 5px;
                        transition: 0.3s;
                        font-weight: 500;
                        a {
                            display: block;
                            width: 100%;
                            height: 100%;
                            font-weight: 500;
                            font-size: 12px;
                            &:hover {
                                color: $primary-color !important;
                                transition: 0.3s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.searchresultmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Màu nền với độ mờ 50%
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; // Đảm bảo modal hiển thị trên cùng
}

.searchResults {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.searchResults li {
    padding: 10px 0;
    border-bottom: 1px solid #eee; // Đường viền phân cách các mục
}

.searchResults li:last-child {
    border-bottom: none; // Loại bỏ đường viền ở mục cuối cùng
}
