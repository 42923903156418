.userModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #000;
    padding: 20px;
    z-index: 2;
    display: none;
    width: 400px;
    height: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &.popup {
        display: block;
    }

    .modal-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 60%;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 8px;
        z-index: 1000;
        padding: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }

        .Edit-input {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            label {
                margin-bottom: 5px;
            }

            input,
            select {
                padding: 8px;
                width: 100%;
                box-sizing: border-box;
                margin-top: 5px;
            }
        }

        .sort_note {
            font-size: 12px;
            color: red;
            margin-top: 10px;
        }
    }
}
.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    button {
        border: none;
        color: #4c5bc1;
        font-weight: 500;
        border-radius: 5px;
        padding: 5px;
        height: 100%;

        background-color: #fff;
        margin: 0 10px;
        cursor: pointer;
        &:hover {
            background-color: #4c5bc1;
            color: #fff;
            transition: 0.5s;
        }
    }
}
.layout {
    max-height: 90%;
    background-color: #000;
    width: 85%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow-y: scroll;
    background-color: #fff;
    .productManage {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 100%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 70px;
        padding: 0 20px;
        h5 {
            border-bottom: solid 1px #aaa;
            margin-bottom: 20px;
        }
        .section {
            .section1,
            .section2,
            .section3 {
                display: flex;
                justify-content: space-around;
                width: 100%;
                .insection1,
                .insection2,
                .insection3 {
                    width: 50%;

                    input {
                        width: 90%;
                    }
                }
            }
        }
        .quill {
            .ql-toolbar {
                position: sticky;
                top: 0;
                z-index: 1000;
                background-color: #fff;
                border-bottom: 1px solid #ccc;
            }
            .ql-editor {
                height: 300px;
            }
        }
        .image-upload-container {
            width: 100px;
            height: 100px;
            border: 2px dashed #ccc;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            position: relative;

            &:hover::after {
                content: "+";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
                color: #aaa;
                font-weight: bold;
            }

            &:hover p {
                opacity: 1;
                text-align: center;
            }

            p {
                text-align: center;
                margin: 0;
                padding: 0;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &:hover .delete-button {
                display: block;
            }

            .delete-button {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;
                color: red;
                background: none;
                border: none;
                cursor: pointer;
            }
        }

        .image-upload-container-group {
            display: flex;
        }

        .uploaded-image {
            width: 100px;
            height: 100px;
            object-fit: contain;
        }
    }
}

.breadcrumb-shop {
    margin-top: -1px;
    background: transparent;
    margin-bottom: 50px;
    padding: 0 0px;
}
.wrapper-pageDetail {
    margin-bottom: 20px;
    .container {
        width: 1170px;
        .row {
            margin-right: -15px;
            margin-left: -15px;
            .pageDetail-content {
                h3 {
                    color: chocolate;
                    font-weight: 600;
                }
            }
            .sidebar-page {
                h3 {
                    color: chocolate;
                    font-weight: 600;
                    font-size: 16px;
                }
                .group-sidebox {
                    margin: 0 0 20px;
                    position: relative;
                    padding: 20px 15px;
                    padding-left: 0;
                    background: #ffffff;
                }
            }
            .menuList-links {
                list-style: none;
                padding: 0;
                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
}
