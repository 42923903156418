.confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    z-index: 1000;
    text-align: center;
}

.confirmation-modal-overlay {
    background-color: rgba(0, 0, 0, 0.3);
}

/* Add any additional styles as needed */
