@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap");
/* CSS Reset */
@import "color";
@import "reset";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Quicksand", sans-serif;
    z-index: 1;
    // scroll-behavior: smooth;
    // background-color: #fff;
}
html {
    scroll-behavior: smooth;
    height: 100%;
}
body {
    position: relative;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

/* Header begin */
header {
    width: 100%;
    height: 12vh;
}

#top {
    position: fixed;
    width: 100%;
    height: 3vh;
    background-color: $primary-color;
    top: 0;
    z-index: 10;
    text-align: center;
    p {
        width: 100%;
        font-size: 14px;
        line-height: 3vh;
        font-weight: 500;
        color: #fff;
    }
}

#log {
    position: fixed;
    width: 70%;
    margin-right: 0;
    top: 0;
    line-height: 3vh;
    margin-left: 80%;
    font-size: 14px;
    font-weight: 600;
}

#social {
    color: #9999;
    list-style: none;
}

#span {
    color: #70706c;
}

.overlayoutNav {
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: center;
    top: 3vh;
    border-bottom: 1px solid #e3e3e3;
    background-color: #fff;
    z-index: 10;
    .navbarHeader {
        display: flex;
        width: 85%;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
        .logo {
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 2px;
            a {
                text-decoration: none;
                text-transform: uppercase;
                color: $primary-color;
            }
        }
        .toggleCatalog {
            text-align: center;
            color: $primary-color;
            font-size: 14px;
            border-radius: 5px;
            height: 40px;
            line-height: 40px;
            padding: 0 5px;
            &:hover {
                color: #fff;
                background-color: $primary-color;
                cursor: pointer;
                transition: 0.5s;
            }
        }

        .searchSection {
            position: relative;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 700px;
            height: 34px;
            border-radius: 5px;
            border: 1px solid #e7e7e7;
            padding: 0;
            padding-left: 15px;
            transition: 0.3s;
            &:focus-within {
                border: 1px solid $primary-color;
                color: $primary-color;
                .iconsearch {
                    color: $primary-color;
                }
            }
            input {
                width: 100%;
                height: 100%;
                font-size: 15px;
                border: none;
                outline: none;
                text-transform: none;
            }
            .iconsearch {
                height: 100%;
                width: 50px;
                text-align: center;
                font-size: 15px;
                border-radius: 5px;
                line-height: 30px;
                color: #8f8787;
                cursor: pointer;
            }
        }
        .contactnumber {
            display: flex;
            justify-content: space-around;
            color: $primary-color;
            align-items: end;
            div {
                line-height: 14px;
                font-size: 14px;
            }
        }
        .icon {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 20px;
            width: 150px;

            .icon-btn {
                position: relative;
                border: none;
                color: $primary-color;
                background-color: transparent;
                margin: 0 10px;
                svg {
                    display: block;
                }
                .cart-icon {
                    .fa-shopping-cart {
                        font-size: 24px; /* Điều chỉnh kích thước biểu tượng giỏ hàng */
                    }
                    #cart-item-count {
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        background-color: #fff;
                        border: 1px solid $primary-color;
                        color: white;
                        border-radius: 50%;
                        padding: 0px;
                        height: 16px;
                        font-size: 12px;
                        line-height: 16px;
                        min-width: 16px;
                        text-align: center;
                        color: $primary-color;
                        font-weight: 600;
                    }
                }

                &:hover {
                    color: #8f8787;
                    cursor: pointer;
                }
            }
        }
    }
}

.menu_bar {
    margin: auto 0;
    display: none;
    font-size: 18px;
    border-radius: 10px;
    div {
        width: 25px;
        height: 3px;
        background-color: #fff;
        margin: 5px;
    }
}

.mainfooter {
    margin-top: 50px;
    border-top: 1px solid #e7e7e7;
    line-height: 1.6;
    a {
        color: #252a2b;
        text-decoration: none;
        outline: none;
        font-size: 14px;
    }
    .footer-info {
        padding: 40px 0 30px;
        a {
            color: #252a2b;
            text-decoration: none;
            outline: none;
            font-size: 14px;
        }
        .container {
            max-width: 1170px;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
            .row {
                margin-right: -15px;
                margin-left: -15px;
            }
        }
    }
    .footer-copyright {
        border-top: 1px solid rgba(37, 42, 43, 0.08);
        padding: 12px 0;

        .container {
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
            p {
                margin: 0;
                padding: 0;
                font-size: 14px;
            }
        }
    }
}
#iconlist {
    display: flex;
    justify-content: space-around;
    margin-right: 25px;
    a {
        border: 1px solid #252a2b;
        padding: 0 8px;
        padding-bottom: 3px;
        font-size: 20px;
        transition: 0.3s;
        &:hover:first-child {
            background-color: #4267b2;
            color: #fff;
        }
        &:hover:nth-child(2) {
            background-color: #e1306c;
            color: #fff;
        }
        &:hover:nth-child(3) {
            background-color: #000;
            color: #fff;
        }
        &:hover:last-child {
            background-color: #ee402d;
            color: #fff;
        }
    }
}
.footerInfo-hline {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;
    .box-icon {
        svg {
            width: 40px;
            height: 40px;
        }
    }
    .box-content {
        display: flex;
        flex-direction: column;
        span {
            strong {
                a {
                    font-size: 20px;
                }
            }
        }
    }
}

.title-footer,
.fter-title {
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 12px;
}
#backtoTop {
    position: fixed;
    bottom: 27px;
    right: 20px;
    border: none;
    background-color: $primary-color;
    color: #fff;
    width: 40px;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
}
